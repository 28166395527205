/*
 * @Author: your name
 * @Date: 2021-03-29 11:48:43
 * @LastEditTime: 2021-03-29 11:48:50
 * @LastEditors: Please set LastEditors
 * @Description: In User Settings Edit
 * @FilePath: \zhgdksh\src\utils\getTime.js
 */
export function getNowTime() {
    let hours, minutes, seconds;
    let date = new Date();
    hours = date.getHours();
    hours = hours > 9 ? hours : "0" + hours;
    minutes = date.getMinutes();
    minutes = minutes > 9 ? minutes : "0" + minutes;
    seconds = date.getSeconds();
    seconds = seconds > 9 ? seconds : "0" + seconds;
    let time = hours + ":" + minutes + ":" + seconds;
    return time
}
export function getNowWeek(){
    let vWeek, vWeek_s;
    let date = new Date();
    vWeek = ["星期天", "星期一", "星期二", "星期三", "星期四", "星期五", "星期六"];
    vWeek_s = date.getDay();
    let week = vWeek[vWeek_s]
    return week
}
export function timestampToTime(d) {
    if(!d){
        return ''
    }else{
        var date = new Date(d);
  var YY = date.getFullYear() + '-';
  var MM = (date.getMonth() + 1 < 10 ? '0' + (date.getMonth() + 1) : date.getMonth() + 1) + '-';
  var DD = (date.getDate() < 10 ? '0' + (date.getDate()) : date.getDate());
  var hh = (date.getHours() < 10 ? '0' + date.getHours() : date.getHours()) + ':';
  var mm = (date.getMinutes() < 10 ? '0' + date.getMinutes() : date.getMinutes()) + ':';
  var ss = (date.getSeconds() < 10 ? '0' + date.getSeconds() : date.getSeconds());
  return YY + MM + DD +" "+hh + mm + ss;
    }
    
  }
  export function timestampToTime1(d) {
    if(!d){
        return ''
    }else{
        var date = new Date(d);
  var YY = date.getFullYear() + '-';
  var MM = (date.getMonth() + 1 < 10 ? '0' + (date.getMonth() + 1) : date.getMonth() + 1) + '-';
  var DD = (date.getDate() < 10 ? '0' + (date.getDate()) : date.getDate());
  return YY + MM + DD
    }
    
  }